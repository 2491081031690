$(function(){

//////////////////////////////////////////////////////////////////
// mobile
//////////////////////////////////////////////////////////////////

// mobile menu 用のタグを書き込む
$('body').append( '<div id="mobile_menu_wrapper"></div>' );

// overlay 用のタグを書き込む
$('body').append( '<div class="drawer-menu-overlay" id="overlay"></div>' );

// nav のソースをコピーして mobile 用の class を付与する
$('#nav').clone().addClass('sm-nav').appendTo('#mobile_menu_wrapper');
$('.sm-nav > ul > li > div > span, .sm-nav > ul > li > div > a').unwrap();

// trigger 用の class を付与する
$('.sm-nav .trigger').addClass('js-trigger');

// burger-wrapper をクリックしたら open-menu class を付与
$('#js-burger').on( 'click', function(){
  $('body').toggleClass( 'open-menu' );
  clear();
});

// オーバーレイをクリックしたらメニューが消える
$('#overlay').on( 'click', function() {
  $('body').removeClass( 'open-menu' );
  clear();
});
 
// 子メニューのアクション
$(document).on( 'click', '.js-trigger', function() {
  const target = $(this).next('ul.child');
  $(this).toggleClass('open');
  $(this).parents('.sm-nav').find('.js-trigger').not(this).removeClass( 'open' );
  $(this).parents('.sm-nav').find('ul.child').not(target).slideUp( 200 );
  target.stop().slideToggle( 200 );
  target.find('.sub-trigger').removeClass( 'open' );
  target.find('.sub-list').slideUp( 200 );
});

function clear(){
  $('.sm-nav .sub-trigger').removeClass( 'open' );
  $('.sm-nav .js-trigger').removeClass( 'open' );
  $('.sm-nav .sub-list').slideUp( 200 );
  $('.sm-nav .child').slideUp( 200 );
}

//////////////////////////////////////////////////////////////////
// pc
//////////////////////////////////////////////////////////////////

//マウスを乗せたときの動作
$('.menu-wrapper').on('mouseenter', function() {
  const w = window.innerWidth;
  if(w >= 980){
    $(this).addClass('current');
  }
});

//マウスを離したときの動作
$('.menu-wrapper').on('mouseleave', function() {
  const w = window.innerWidth;
  if(w >= 980){
    $(this).removeClass('current');
    $(this).find('.sub-list').slideUp( 200 );
    $('.sub-trigger').removeClass('open');
  }
});

//////////////////////////////////////////////////////////////////
// common
//////////////////////////////////////////////////////////////////

//トリガークリック
$('.sub-trigger').on('click', function(){
  const target = $(this).next('.sub-list');
  $(this).toggleClass('open');
  target.stop().slideToggle( 200 );
  $(this).parents('.child').find('.sub-trigger').not(this).removeClass( 'open' );
  $(this).parents('.child').find('.sub-list').not(target).slideUp( 200 );
});

//resize
$(window).on('resize', function(){
  $('.sub-list').slideUp( 200 );
  $('.sub-trigger').removeClass('open');
  $('body').removeClass( 'open-menu' );
  $('.sm-nav .child').slideUp( 200 );
  $('.sm-nav .js-trigger').removeClass( 'open' );
});

//////////////////////////////////////////////////////////////////
// side-navigation
//////////////////////////////////////////////////////////////////

//トリガークリック
$('.side-navigation-trigger').on('click', function(){
  const target = $(this).next('.side-navigation-child');
  $(this).toggleClass('open');
  target.stop().slideToggle( 200 );
});

//resize
$(window).on('resize', function(){
  $('.side-navigation-child').slideUp( 200 );
  $('.side-navigation-trigger').removeClass('open');
});

}); // end
