$(function(){


// タブ
const tab     = '.tab';
const label   = $(tab).find('.tab-label');
const content = $(tab).find('.tab-content');
const tag     = label.find('a');
const current = 'current';

showTabContent();

tag.on('click', function(e){
  const self = $(this);
  e.preventDefault();
  label.find('li').removeClass(current);
  self.parent().addClass(current);
  showTabContent();
});

function showTabContent(){
  const id = $( '.' + current ).find('a').attr('href');
  content.find('>*').hide();
  $(id).fadeIn();
}


// PageTop
const pagetop = $('#pageTop');
$(window).on('scroll', function () {
  if( $(this).scrollTop() > 100 ) {
    pagetop.fadeIn();
  } else {
    pagetop.fadeOut();
  }
});

pagetop.on('click', function(){
  $('body, html').animate({ scrollTop : 0 }, 400);
  return false;
});


// ページ内リンク
$('a[href^="#"]').click(function(){
  var speed = 500;
  var href= $(this).attr("href");
  var target = $(href == "#" || href == "" ? 'html' : href);
  var position = target.offset().top;
  $("html, body").animate({scrollTop:position}, speed, "swing");
  return false;
});


// アコーディオン
const getStyle = (el, styleProp) => {
  let value, defaultView = ( el.ownerDocument || document ).defaultView;
  // W3C standard way:
  if ( defaultView && defaultView.getComputedStyle ) {
    // sanitize property name to css notation
    // (hypen separated words eg. font-Size)
    styleProp = styleProp.replace(/([A-Z])/g, "-$1").toLowerCase();
    return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
  } else if ( el.currentStyle ) { // IE
    // sanitize property name to camelCase
    styleProp = styleProp.replace(/\-(\w)/g, function(str, letter) {
      return letter.toUpperCase();
    });
    value = el.currentStyle[styleProp];
    // convert other units to pixels on IE
    if (/^\d+(em|pt|%|ex)?$/i.test(value)) {
      return (function(value) {
        var oldLeft = el.style.left, oldRsLeft = el.runtimeStyle.left;
        el.runtimeStyle.left = el.currentStyle.left;
        el.style.left = value || 0;
        value = el.style.pixelLeft + "px";
        el.style.left = oldLeft;
        el.runtimeStyle.left = oldRsLeft;
        return value;
      })(value);
    }
    return value;
  }
}
// アコーディオン処理
const acc = document.getElementsByClassName('js-accordion-trigger');
let i;
// ページを開いた時にアコーディオンを開いた状態にしたいときの処理
//const firstAccordion = acc[0];
//const firstPanel = firstAccordion.nextElementSibling;
//firstAccordion.classList.add('active');
//firstPanel.style.maxHeight = firstPanel.scrollHeight + 'px';

// Add onclick listener to every accordion element
for ( i = 0; i < acc.length; i++ ) {
  acc[i].addEventListener('click', function () {
    // For toggling purposes detect if the clicked section is already 'active'
    const isActive = this.classList.contains('active');
    // Close all accordions
    // const allAccordions = document.getElementsByClassName('js-accordion-trigger');
    // for ( let j = 0; j < allAccordions.length; j++) {
    //   // Remove active class from section header
    //   allAccordions[j].classList.remove('active');
    //   // Remove the max-height class from the panel to close it
    //   const panel = allAccordions[j].nextElementSibling;
    //   const maxHeightValue = getStyle(panel, 'maxHeight');

    //   if (maxHeightValue !== '0px') {
    //     panel.style.maxHeight = null;
    //   }
    // }
    // Toggle the clicked section using a ternary operator
    isActive ? this.classList.remove('active') : this.classList.add('active');
    // Toggle the panel element
    const panel = this.nextElementSibling;
    const maxHeightValue = getStyle( panel, 'maxHeight' );
    if (maxHeightValue !== '0px') {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  })
}

});

